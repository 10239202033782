import * as React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { graphql, PageProps } from "gatsby";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Container from "@material-ui/core/Container";
import ScheduleIcon from "@material-ui/icons/Schedule";
import CalendarTodayRoundedIcon from "@material-ui/icons/CalendarTodayRounded";
import { styled } from "@material-ui/core/styles";
import { Link } from "../components/Link";
import { Meta } from "../components/Meta";

const StyledChip = styled(Chip)(({ theme }) => ({
  "& .MuiChip-iconSmall": {
    marginLeft: theme.spacing(1),
    fontSize: ".95rem",
  },
}));

type MdxPageTemplateProps = PageProps & {
  data: {
    mdx: {
      fields: {
        editUrl: string;
        readingTime: {
          minutes: number;
        };
      };
      frontmatter: { title: string; subtitle: string | null; date: string };
      body: string;
    };
  };
};

const MdxPageTemplate: React.FC<MdxPageTemplateProps> = (props) => {
  const {
    data: {
      mdx: {
        fields: {
          editUrl,
          readingTime: { minutes },
        },
        frontmatter: { title, subtitle, date },
        body,
      },
    },
  } = props;

  return (
    <Container maxWidth="md" sx={{ pl: 1, pr: 1 }} id="guides-content">
      <Meta title={title} />
      <Box sx={{ flexGrow: 1 }}>
        <Box sx={{ mt: 10, mb: 6 }}>
          <Typography variant="h1" component="h1">
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="body2" color="text.secondary">
              {subtitle}
            </Typography>
          )}

          <StyledChip
            size="small"
            variant="outlined"
            color="secondary"
            icon={<ScheduleIcon />}
            label={`${Math.ceil(minutes)} minute read`}
            sx={{ mr: 1, mt: 1 }}
          />

          <StyledChip
            size="small"
            variant="outlined"
            color="secondary"
            icon={<CalendarTodayRoundedIcon />}
            label={date}
            sx={{ mr: 1, mt: 1 }}
          />

          <StyledChip
            size="small"
            component={Link}
            variant="outlined"
            color="primary"
            to={editUrl}
            icon={<ExitToAppIcon />}
            label="edit"
            clickable
            sx={{ mr: 1, mt: 1 }}
          />
        </Box>
        <MDXRenderer>{body}</MDXRenderer>
      </Box>
    </Container>
  );
};

export const query = graphql`
  query ($slug: String!) {
    site {
      meta: siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      fields {
        slug
        editUrl
        readingTime {
          minutes
        }
      }
      frontmatter {
        title
        subtitle
        status
        date(formatString: "MMM YYYY")
      }
      timeToRead
      tableOfContents
      body
    }
  }
`;

export default MdxPageTemplate;
